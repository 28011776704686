<script lang="ts" setup>
defineProps({
  business: String,
  width: {
    type: [Number, String],
    default: 477,
  },
  height: {
    type: [Number, String],
    default: 122,
  },
  fillColor: {
    type: String,
    default: 'currentColor',
  },
  strokeColor: {
    type: String,
    default: 'currentColor',
  },
})
</script>

<template>
  <div
    class="skribble-logo"
    :class="{
      'skribble-business': business,
      'skribble-business-dark': business === 'dark',
    }"
  >
    <nuxt-img src="/img/logo-skribble.svg" width="54" height="30" alt="Skribble" />
    <div class="skribble-logo-text">Skribble</div>
    <small v-if="business">Admin</small>
  </div>
</template>

<style lang="sass">
.skribble-logo
  display: flex
  align-items: center
  font: bold 1.875rem/1 $averta
  color: $c-text-alt
  +maw(xs)
    font-size: 1.5rem
  &:hover
    color: $c-text-alt

  &.push-right
    +max-width
      // make room for the mobile nav trigger
      margin-left: 50px
  img
    flex-shrink: 0
    margin-right: 3px
    height: auto
    +maw(xs)
      width: 40px

  small
    margin-top: 5px
    margin-left: 7px
    font: normal 0.65rem/1 $averta
    color: white

  &.skribble-business
    &.push-right
      +maw(sm)
        margin-left: 0
    +maw(sm)
      font-size: 1.2rem
    .skribble-logo-text
      color: white
      font-size: 29px
      margin-right: 6px
      letter-spacing: -0.7px
      +maw(sm)
        font-size: 25px
        letter-spacing: -0.6px

    small
      margin-top: 0
      margin-left: 0
      font: normal 18px/1.2 $averta
      color: white
      letter-spacing: -0.7px
      height: 100%
      display: flex
      align-items: flex-end
      +maw(sm)
        font-size: 16px
        letter-spacing: -0.9px
        line-height: 1.1
    img
      margin-right: 6px
      width: 50px
      height: auto
      +maw(sm)
        width: 42px
    &-dark
      small
        color: $c-grey-dark-2

      .skribble-logo-text
        color: $c-text-alt
</style>
