<template>
  <div class="logo" :class="{ 'logo--business': business }">
    <img v-if="image" class="logo__img" :src="image" />
    <skribble-logo v-else class="logo__img" :business="business" />
  </div>
</template>

<script lang="ts">
import SkribbleLogo from '@/components/SkribbleLogo.vue'

export default defineComponent({
  name: 'Logo',
  components: {
    SkribbleLogo,
  },
  props: {
    business: String,
    image: String,
  },
})
</script>

<style lang="sass">

$ns: logo
$logo-stage-width: 190px
$logo-stage-height: 50px

$logo-stage-width-business: 250px
$logo-stage-height-business: 27.69px

.#{$ns}
  display: flex
  align-items: center
  width: $logo-stage-width
  height: $logo-stage-height
  box-sizing: content-box
  +maw(xs)
    justify-content: center
    width: .75 * $logo-stage-width
    height: .75 * $logo-stage-height

  &__img
    max-width: $logo-stage-width
    max-height: $logo-stage-height
    +maw(xs)
      max-width: .75 * $logo-stage-width
      max-height: .75 * $logo-stage-height

  &--business
    width: max-content
    height: $logo-stage-height-business
    margin-left: 0 !important
    +maw(sm)
      height: 23.26px

    .#{$ns}__img
      max-width: $logo-stage-width-business
      height: $logo-stage-height-business
      +maw(sm)
        height: 23.26px

      img
        height: 100%
        display: block
</style>
